import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Judges Have Enormous Power" keywords={[`Judicial`, `Elections`, `Philadelphia`]} />
    <p>You decide who becomes a judge in Philadelphia. Our <a href="https://philadelphiabar.org/?pg=JudicialCommission">Judicial Commission</a>'s objective, nonpartisan investigations rate candidates on their ability, integrity and judgment. Make an informed decision.</p>
    <h1>2025 Primary Election Ratings</h1>
    <h2>Commonwealth Court</h2>
    <div className="tableouter">
      <table>
        <tbody>
          <tr>
            {/* <th className="button_num">Button #</th> */}
            <th>Name</th>
            <th>Recommendation</th>
          </tr>
          {data.allJudgesJson.edges.map(({ node }, index) => (
            <tr key={index}>
              {/* <td className="button_num">
                {node.status === 0 && <span>{node.ballotpos}</span>}
                {node.status === 1 && <span>{node.ballotpos}</span>}
                {node.status === 2 && <strong className="highly">{node.ballotpos}</strong>}
              </td> */}
                {node.status === 0 && <td>{node.name}</td>}
                {node.status === 1 && <td>{node.name}</td>}
                {node.status === 2 && <td><strong className="highly">{node.name}</strong></td>}
                {node.status === 3 && <td>{node.name}</td>}
              <td>
                {node.status === 0 && <span className="notrecommended">Not Recommended</span>}
                {node.status === 1 && <span className="recommended">Recommended</span>}
                {node.status === 2 && <strong className="highly recommendedd">Highly Recommended</strong>}
                {node.status === 3 && <span>Not Evaluated</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <h2>Court of Common Pleas</h2>
    <div className="tableouter">
      <table>
        <tbody>
          <tr>
            {/* <th className="button_num">Button #</th> */}
            <th>Name</th>
            <th>Recommendation</th>
          </tr>
          {data.allJudges3Json.edges.map(({ node }, index) => (
            <tr key={index}>
              {/* <td className="button_num">
                {node.status === 0 && <span>{node.ballotpos}</span>}
                {node.status === 1 && <span>{node.ballotpos}</span>}
                {node.status === 2 && <strong className="highly">{node.ballotpos}</strong>}
              </td> */}
                {node.status === 0 && <td>{node.name}</td>}
                {node.status === 1 && <td>{node.name}</td>}
                {node.status === 2 && <td><strong className="highly">{node.name}</strong></td>}
                {node.status === 3 && <td>{node.name}</td>}
              <td>
                {node.status === 0 && <span className="notrecommended">Not Recommended</span>}
                {node.status === 1 && <span className="recommended">Recommended</span>}
                {node.status === 2 && <strong className="highly recommendedd">Highly Recommended</strong>}
                {node.status === 3 && <span>Not Evaluated</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <h2>Municipal Court</h2>
    <div className="tableouter">
      <table>
        <tbody>
          <tr>
            {/* <th className="button_num">Button #</th> */}
            <th>Name</th>
            <th>Recommendation</th>
          </tr>
          {data.allJudges2Json.edges.map(({ node }, index) => (
            <tr key={index}>
              {/* <td className="button_num">
                {node.status === 0 && <span>{node.ballotpos}</span>}
                {node.status === 1 && <span>{node.ballotpos}</span>}
                {node.status === 2 && <strong className="highly">{node.ballotpos}</strong>}
              </td> */}
                {node.status === 0 && <td>{node.name}</td>}
                {node.status === 1 && <td>{node.name}</td>}
                {node.status === 2 && <td><strong className="highly">{node.name}</strong></td>}
                {node.status === 3 && <td>{node.name}</td>}
              <td>
                {node.status === 0 && <span className="notrecommended">Not Recommended</span>}
                {node.status === 1 && <span className="recommended">Recommended</span>}
                {node.status === 2 && <strong className="highly recommendedd">Highly Recommended</strong>}
                {node.status === 3 && <span>Not Evaluated</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  
  </Layout>
)

export default IndexPage

export const query = graphql`
query {
  allJudges6Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudges5Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudge4Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudges3Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudges2Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudges6Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudges7Json {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
  allJudgesJson {
    edges {
      node {
        name
        status
        ballotpos
      }
    }
  }
}
`